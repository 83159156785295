/**
 * merged spec
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * All preferences requested for the user
 */
export interface PreferenceDto { 
    /**
     * Preference name
     */
    name?: PreferenceDtoNameEnum;
    /**
     * Value of the preference
     */
    value?: string;
}
export enum PreferenceDtoNameEnum {
    Email = 'NOTIFICATION_RECEIVE_EMAIL',
    Sms = 'NOTIFICATION_RECEIVE_SMS',
    Portal = 'NOTIFICATION_RECEIVE_PORTAL'
};



